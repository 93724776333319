import React from "react";
import bg25 from "./images/top-curve.png";
import "./section35.css";
export default function Section35() {
  return (
    <div className="one-section35">
      <img src={bg25} className="img-responsive center-block" alt="background25" />
      <div className="one-section one-section-35 ">
        <div className="container">
          <div className="col-md-12">
            <div className="row justify-content-center">
            <h1 className="fw500 lh-5 text-center text-white max-w1100 mx-auto mb-5">Still, wondering why you haven’t seen the desired profits? Maybe you are not using <b className="text-yellow2">Mumara One</b></h1>
            <div className="col-md-5">
              <ul className="items-lists">
                <li>Optimum results in a cost-effective manner</li>
                <li>Grow your email list</li>
                <li>Boost engagement</li>
                <li>Generate targeted leads</li>
                <li>Increase sales and profits</li>
                <li>Build excitement to improve awareness</li>
              </ul>
            </div>
            <div className="col-md-5">
              <ul className="items-lists">
                <li>Get more by doing less with pre-built templates</li>
                <li>Get more product exposure</li>
                <li>Market with a personal touch</li> <li>Save time to stay top-of-mind</li>
                <li>Create two-communication process</li>
                <li>Inch-perfect emails to the ready-to-convert users</li>
              </ul>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
