import React from "react";
import check33 from "./images/check.png";
import mamico from "./images/Messaging-amico.svg";

import "./section33.css";
export default function Section33() {
  return (
    <div className="one-section one-section-33">
      <div className="container">
        <div className="col-md-12">
          <div className="row justify-content-center pb-5">
            <div className="col-md-6 align-self-center">
              <img src={mamico} className="img-fluid max-w500 mx-auto d-block money" alt="money" />
            </div>
            <div className="col-md-6 align-self-center">
              <h2 className="fw500 lh-5 text-white"> Get Started with <span className="text-white fw700">Mumara One</span> for <span className="text-white fw600">Free</span>! </h2>
              <p className="fs-20 fw400 lh-5 m-0 text-white">Email marketing through Mumara One is <span className="text-white fw600">cost-effective</span>. </p>
              <div className="mt-5 text-left">
                <div className="list-overflow-green144">
                  <img src={check33} className="bulletlistgreen14 img-responsive" alt="check" />
                  <p className="text-15 text-white list-paddingxx">Send your <b className="text-white">Email Campaign </b> instantly. </p>
                </div>
                <div className="list-overflow-green144">
                  <img src={check33} className="bulletlistgreen14 img-responsive" alt="check" />
                  <p className="text-15 text-white list-paddingxx">You can start using it with a <b className="text-white">free account</b>. </p>
                </div>
                <div className="list-overflow-green144">
                  <img src={check33} className="bulletlistgreen14 img-responsive" alt="check" />
                  <p className="text-15 text-white list-paddingxx"><b className="text-white">Upgrade your Plan</b> when you start getting the results. </p>
                </div>
                <div className="list-overflow-green144">
                  <img src={check33} className="bulletlistgreen14 img-responsive" alt="check" />
                  <p className="text-15 text-white list-paddingxx"><b className="text-white">Send your Emails</b> whenever you want . </p>
                </div>
                <div className="list-overflow-green144">
                  <img src={check33} className="bulletlistgreen14 img-responsive" alt="check" />
                  <p className="text-15 text-white list-paddingxx">
                    <b className="text-white"> Email marketing</b> is targeted and cost-effective.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
