import React from 'react'
import { graphql } from 'gatsby'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import ModalSimple from '@solid-ui-blocks/Modal/Block02'
import Header from '@solid-ui-blocks/Header/Block01'
import SubHeader from '@solid-ui-blocks/SubHeader/SubHeader'
/************  BEGIN SECTION  ************/
import Section1 from '@solid-ui-blocks/OneSale/Section1/Section1'
import Section2 from '@solid-ui-blocks/OneSale/Section2/Section2'
import Section3 from '@solid-ui-blocks/OneSale/Section3/Section3'
import Section4 from '@solid-ui-blocks/OneSale/Section4/Section4'
import Section5 from '@solid-ui-blocks/OneSale/Section5/Section5'
import Section6 from '@solid-ui-blocks/OneSale/Section6/Section6'
import Section7 from '@solid-ui-blocks/OneSale/Section7/Section7'
import Section8 from '@solid-ui-blocks/OneSale/Section8/Section8'
import Section9 from '@solid-ui-blocks/OneSale/Section9/Section9'
import Section10 from '@solid-ui-blocks/OneSale/Section10/Section10'
import Section11 from '@solid-ui-blocks/OneSale/Section11/Section11'
import Section12 from '@solid-ui-blocks/OneSale/Section12/Section12'
import Section13 from '@solid-ui-blocks/OneSale/Section13/Section13'
import Section14 from '@solid-ui-blocks/OneSale/Section14/Section14'
import Section15 from '@solid-ui-blocks/OneSale/Section15/Section15'
import Section16 from '@solid-ui-blocks/OneSale/Section16/Section16'
import Section17 from '@solid-ui-blocks/OneSale/Section17/Section17'
import Section18 from '@solid-ui-blocks/OneSale/Section18/Section18'
import Section19 from '@solid-ui-blocks/OneSale/Section19/Section19'
import Section20 from '@solid-ui-blocks/OneSale/Section20/Section20'
import Section21 from '@solid-ui-blocks/OneSale/Section21/Section21'
import Section22 from '@solid-ui-blocks/OneSale/Section22/Section22'
import Section23 from '@solid-ui-blocks/OneSale/Section23/Section23'
import Section24 from '@solid-ui-blocks/OneSale/Section24/Section24'
import Section25 from '@solid-ui-blocks/OneSale/Section25/Section25'
import Section26 from '@solid-ui-blocks/OneSale/Section26/Section26'
import Section27 from '@solid-ui-blocks/OneSale/Section27/Section27'
import Section28 from '@solid-ui-blocks/OneSale/Section28/Section28'
import Section29 from '@solid-ui-blocks/OneSale/Section29/Section29'
import Section30 from '@solid-ui-blocks/OneSale/Section30/Section30'
import Section31 from '@solid-ui-blocks/OneSale/Section31/Section31'
import Section32 from '@solid-ui-blocks/OneSale/Section32/Section32'
import Section33 from '@solid-ui-blocks/OneSale/Section33/Section33'
import Section34 from '@solid-ui-blocks/OneSale/Section34/Section34'
import Section35 from '@solid-ui-blocks/OneSale/Section35/Section35'
import Section36 from '@solid-ui-blocks/OneSale/Section36/Section36'
import Section37 from '@solid-ui-blocks/OneSale/Section37/Section37'
import Section38 from '@solid-ui-blocks/OneSale/Section38/Section38'
import Section40 from '@solid-ui-blocks/OneSale/Section40/Section40'
import Faq from '@solid-ui-blocks/OneSale/FaqBlock40'
import Footer from '@solid-ui-blocks/Footer/Block01'
/************  END SECTION  ************/
import { normalizeBlockContentNodes } from '@blocks-helpers'
import theme from './_theme'
import "./fonts/font.css";
import './main.css'
import Section41 from '@solid-ui-blocks/OneSale/Section41/Section41'
import Section42 from '@solid-ui-blocks/OneSale/Section42/Section42'






const HomePage = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout theme={theme} {...props}>
      <Seo title='Home' />
      {/* Modals */}
      <ModalWithTabs content={content['authentication']} reverse />
      <ModalWithTabs content={content['contact']} />
      <ModalSimple content={content['advertisement']} />
      {/* Blocks */}
      <Header content={content['header']} />
      <div className="subheader-block">
        <SubHeader content={content['subheader_one']} menuJustify='space-between' />
      </div>
      {/* <Divider space='4' /> */}
      {/* SECTIONS START */}
      <div className="main">
        <Section1 />
        <Section2 />
        <Section41 />
        <Section42 />
        <Section3 />
        <Section4 />
        <Section5 />
        <Section6 />
        <Section7 />
        <Section8 />
        <Section9 />
        <Section10 />
        <Section11 />
        <Section12 />
        <Section13 />
        <Section14 />
        <Section15 />
        <Section16 />
        <Section17 />
        <Section18 />
        <Section19 />
        <Section20 />
        <Section21 />
        <Section22 />
        <Section23 />
        <Section24 />
        <Section25 />
        <Section26 />
        <Section27 />
        <Section28 />
        <Section29 />
        <Section30 />
        <Section31 />
        <Section32 />
        <Section33 />
        <Section34 />
        <Section35 />
        <Section36 />
        <Section37 />
        <Section38 />
        <Section40 />
        
        <Faq content={content['faq']} />
      </div>

      {/* SECTIONS END */}


      <Footer content={content['footer']} />
    </Layout>
  )
}

export const query = graphql`
  query miscIndexBlockContent {
    allBlockContent(
      filter: { page: { in: ["homepage/OneSale", "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default HomePage
