import React from "react";
import uncheckIcon34 from "./images/uncheckIcon.png";
import checkIcon34 from "./images/checkIcon.png";
import arrow from "./images/arrow.png";
import paytm from "./images/paytm.png";

import "./section34.css";
export default function Section34() {
  return (
    <div className="one-section one-section-34 ">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            <h1 className="text-center fw500 lh-5">Decide Your <span className="text-sea-green fw700">Destiny</span> Yourself</h1>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="col-lg-6 col-md-6 without-list-box mt3">
                <div className="without-list">
                  <h3 className="fw400 lh-5 text-white">
                    <span className="without-box"> <img src={uncheckIcon34} className="img-fluid center-block uncheckIcon" alt="uncheck" />    LOSING SIDE </span>
                  </h3>
                  <ul className="without-checklist fw400 lh-6">
                    <li>Continue using traditional methods.</li>
                    <li>Keep spending on social media and google campaigns without a plan. </li>
                    <li>Keep breaking your back by running after the customers. </li>
                    <li>Continue paying hard-earned money to advertising agencies. </li>
                    <li>Continue failing. </li>
                    <li>Keep yourself out of the competition. </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 with-list-box mt3">
                <div className="with-list">
                  <h3 className="fw400 lh-5 text-white text-right">
                    <span className="with-box">  WINNING SIDE <img src={checkIcon34} className="img-fluid center-block checkIcon" alt="check" />  </span>
                  </h3>
                  <ul className="with-checklist fw400 lh-6">
                    <li> Use Mumara One with jaw-dropping features without any effort. </li>
                    <li> Save your dollars without hiring any professionals. </li>
                    <li> Collect more leads and convert them into sales. </li>
                    <li> Select from the dozens of professionally designed templates. </li>
                    <li>Manage your contact list and segment them according to users’ preferences. </li>
                    <li> Dismiss old trends and step into the new world of wonders. </li>
                    <li> Nurture your leads and increase ROI. </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 text-center mt-5">
            <div className="inner-box"><h1>Keep Standing on the <b>Winning Side</b>,<br /> We Are Here to Help You Out</h1></div>
          </div>
        </div>
      </div>
    </div>
  );
}
